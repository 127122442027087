import React from "react";

import BackToTop from "react-back-to-top-button";
import Footer from "components/footer";
import styled from "styled-components";
import Logo from "components/logo-mobile";

import Header from "./header";
import Link from "next/link";

const Main = styled.main`
  --offset: calc(calc(100vw - var(--content-w)) / 2);
  padding: 0 var(--offset);
  margin: 0 auto;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const Arrow = styled.div`
  --color: rgba(0, 0, 0, 0.8);
  width: 2vmin;
  height: 2vmin;
  box-sizing: border-box;
  position: absolute;
  right: 30px;
  bottom: 30px;
  transform: rotate(-45deg);

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 3px 3px 0 0;
    border-style: solid;
    border-color: var(--color);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }
`;

const LogoMobile = styled.div`
  width: 100px;
  height: auto;
  margin: 30px auto 0 auto;
  @media (min-width: 767px) {
    display: none;
  }
`;

export default function Layout({ pages, networks, mentionsLegales, children }) {
  return (
    <>
      <Header siteTitle="Didier Ropers photographe" pages={pages} />
        <Link href="/">
          <LogoMobile>
            <Logo />
          </LogoMobile>
        </Link>
      <Main>{children}</Main>
      {networks && pages && <Footer networks={networks} pages={pages} />}
      <BackToTop showAt={1500} speed={1500} easing="easeInOutQuint">
        <Arrow />
      </BackToTop>
    </>
  );
}
