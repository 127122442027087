import { gql, useQuery } from "@apollo/client";
import { addApolloState, initializeApollo } from "lib/graphql/apolloClient";
import Layout from "components/layout";
import dynamic from "next/dynamic";

import {
  COLLECTION_QUERY,
  DIAPORAMA_BLOCK_FIELDS,
  IMAGE_CHOOSER_BLOCK_FIELDS,
  LIST_BLOCK_FIELDS,
  QUICK_LINK_BLOCK_FIELDS,
} from "queries/page";

export const PAGE_QUERY = gql`
  ${LIST_BLOCK_FIELDS}
  ${QUICK_LINK_BLOCK_FIELDS}
  ${DIAPORAMA_BLOCK_FIELDS}
  ${IMAGE_CHOOSER_BLOCK_FIELDS}
  query page($urlPath: String!) {
    site {
      siteName
    }
    collections(limit: 1000) {
      id
      name
    }
    networks(limit: 1000) {
      name
      link
    }
    pages(limit: 1000) {
      __typename
      ... on HomePage {
        id
        footer
        footerSeo
        children {
          ... on ArticleIndexPage {
            excludeFromMenus
          }
          ... on GalleryPage {
            excludeFromMenus
          }
          ... on StandardPage {
            excludeFromMenus
          }
          id
          title
          slug
        }
      }
    }
    events {
      name
      id
    }
    page(urlPath: $urlPath) {
      pageType
      urlPath
      id
      title
      seoTitle
      searchDescription
      ... on ArticlePage {
        subtitle
        thumbnail {
          id

          rendition(width: 800) {
            url
          }
        }
        cover {
          id

          rendition(width: 2000) {
            url
          }
        }
        body {
          blockType
          field
          id
          ... on ParagraphBlock {
            inverted
            align
            texte
            format
            paragraphImage: image {
              id

              rendition(width: 1200) {
                url
              }
            }
          }
          ... on AnimatedHeadingBlock {
            value
          }

          ... on ListBlock {
            ...ListBlockFields
          }
          ... on HeadingBlock {
            value
          }
          ... on URLBlock {
            value
          }
          ... on CharBlock {
            value
          }
          ... on RichTextBlock {
            value
          }
          ... on QuickLinkBlock {
            ...QuickLinkBlockFields
          }
          ... on DiaporamaBlock {
            ...DiaporamaBlockFields
          }
          ... on ImageChooserBlock {
            ...ImageChooserBlockFields
          }
        }
      }
      ... on GalleryPage {
        subtitle
        thumbnail {
          id

          rendition(width: 800) {
            url
          }
        }
        cover {
          id

          rendition(width: 2000) {
            url
          }
        }
        body {
          blockType
          field
          id
          ... on ParagraphBlock {
            inverted
            align
            texte
            format
            paragraphImage: image {
              id

              rendition(width: 1200) {
                url
              }
            }
          }
          ... on AnimatedHeadingBlock {
            value
          }

          ... on ListBlock {
            ...ListBlockFields
          }
          ... on HeadingBlock {
            value
          }
          ... on URLBlock {
            value
          }
          ... on CharBlock {
            value
          }
          ... on RichTextBlock {
            value
          }
          ... on QuickLinkBlock {
            ...QuickLinkBlockFields
          }
          ... on DiaporamaBlock {
            ...DiaporamaBlockFields
          }
          ... on ImageChooserBlock {
            ...ImageChooserBlockFields
          }
        }
      }
      ... on StandardPage {
        subtitle
        body {
          blockType
          field
          id
          ... on ParagraphBlock {
            inverted
            align
            texte
            format
            paragraphImage: image {
              id

              rendition(width: 1200) {
                url
              }
            }
          }
          ... on AnimatedHeadingBlock {
            value
          }

          ... on ListBlock {
            ...ListBlockFields
          }
          ... on HeadingBlock {
            value
          }
          ... on URLBlock {
            value
          }
          ... on CharBlock {
            value
          }
          ... on RichTextBlock {
            value
          }
          ... on QuickLinkBlock {
            ...QuickLinkBlockFields
          }
          ... on DiaporamaBlock {
            ...DiaporamaBlockFields
          }
          ... on ImageChooserBlock {
            ...ImageChooserBlockFields
          }
        }
      }
      ... on ArticleIndexPage {
        children(order: "-first_published_at", limit: 1000) {
          id
          ... on ArticlePage {
            title
            subtitle
            url
            slug
            thumbnail {
              id

              rendition(width: 1200) {
                url
              }
            }
          }
        }
      }
      ... on HomePage {
        subtitle
        body {
          blockType
          field
          id
          ... on ParagraphBlock {
            texte
            format
            inverted
            align
            paragraphImage: image {
              id

              rendition(width: 1200) {
                url
              }
            }
          }
          ... on AnimatedHeadingBlock {
            value
          }

          ... on ListBlock {
            ...ListBlockFields
          }
          ... on HeadingBlock {
            value
          }
          ... on URLBlock {
            value
          }
          ... on CharBlock {
            value
          }
          ... on RichTextBlock {
            value
          }
          ... on QuickLinkBlock {
            ...QuickLinkBlockFields
          }
          ... on DiaporamaBlock {
            ...DiaporamaBlockFields
          }
          ... on ImageChooserBlock {
            ...ImageChooserBlockFields
          }
        }
      }
    }
  }
`;

const ALL_PAGES_QUERY = gql`
  query allPages {
    pages(limit: 1000) {
      id
      url
    }
  }
`;

export const LazyContainer = {
  ArticleIndexPage: dynamic(() => import(`containers/ArticleIndexPage`)),
  ArticlePage: dynamic(() => import(`containers/ArticlePage`)),
  GalleryPage: dynamic(() => import(`containers/GalleryPage`)),
  HomePage: dynamic(() => import(`containers/HomePage`)),
  StandardPage: dynamic(() => import(`containers/StandardPage`)),
};

export default function CatchAllPage({ urlPath }) {
  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: { urlPath },
  });

  if (loading) {
    return <>loading</>;
  }

  if (error) {
    console.error("Error in CatchAllPage", error);
    return <>Uh oh</>;
  }

  const Component = LazyContainer[data?.page.pageType];

  return (
    <Layout
      pages={data.pages}
      mentionsLegales={data.mentionsLegales}
      networks={data.networks}
    >
      <Component
        {...data.page}
        collections={data.collections}
        site={data.site}
        events={data.events}
        seo={{
          seoHtmlTitle: data.page.seoTitle,
          seoMetaDescription: data.page.searchDescription,
          seoOgDescription: data.page.searchDescription,
        }}
      />
    </Layout>
  );
}

export async function getStaticPaths() {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query({
    query: ALL_PAGES_QUERY,
  });

  const paths = data.pages
    .filter(({ url }) => url !== "/")
    .map(
      ({ url }) =>
        url && {
          params: { path: url.split("/").filter((part) => part !== "") },
        },
    );

  return {
    paths,
    fallback: true,
  };
}

export async function getStaticProps({ params }) {
  const urlPath = params?.path.join("/") || "/";

  const apolloClient = initializeApollo();

  const { data, error } = await apolloClient.query({
    query: PAGE_QUERY,
    variables: {
      urlPath,
    },
  });

  if (error) {
    console.error("Error in getStaticProps", error);
  }

  if (data?.page?.body) {
    // Dans le cas où le body de la page contient un diaporama,
    // on fetch les images en deux temps, à partir du nom de la collection.
    const slideshows = data.page.body.filter(
      ({ blockType }) => blockType === "DiaporamaBlock",
    );
    for (const { collection } of slideshows) {
      const collectionId = data.collections
        .map(({ id, name }) => {
          if (name === collection) {
            return id;
          } else {
            return;
          }
        })
        .join("");

      await apolloClient.query({
        query: COLLECTION_QUERY,
        variables: { id: collectionId },
      });
    }
  }

  return {
    ...addApolloState(apolloClient, {
      props: { urlPath },
    }),
    revalidate: 1,
  };
}
