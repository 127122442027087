import { gql } from "@apollo/client";
export const COLLECTION_QUERY = gql`
  query CollectionQuery($id: ID!) {
    images(collection: $id, order: "title", limit: 1000) {
      id
      collection {
        id
        name
      }

      file
      title
      rendition(width: 1200) {
        url
        file
      }
    }
  }
`;

export const IMAGE_CHOOSER_BLOCK_FIELDS = gql`
  fragment ImageChooserBlockFields on ImageChooserBlock {
    image {
      id
      file
      rendition(width: 1200) {
        url
      }
    }
  }
`;

export const DIAPORAMA_BLOCK_FIELDS = gql`
  fragment DiaporamaBlockFields on DiaporamaBlock {
    loop
    inverted
    music {
      file
    }
    title
    transition
    duration
    collection
  }
`;

export const QUICK_LINK_BLOCK_FIELDS = gql`
  fragment QuickLinkBlockFields on QuickLinkBlock {
    externalUrl
    align
    format
    text
    page {
      id
      url
    }
    quickLinkImage: image {
      id

      title
      rendition(width: 1200) {
        url
      }
    }
  }
`;

export const LIST_BLOCK_FIELDS = gql`
  fragment ListBlockFields on ListBlock {
    items {
      ... on ImageChooserBlock {
        image {
          file
          id

          rendition(width: 1200) {
            url
          }
        }
      }
      ... on PageChooserBlock {
        page {
          slug
          url
          title
          ... on GalleryPage {
            subtitle
            thumbnail {
              id

              rendition(width: 400) {
                url
              }
            }
          }
          ... on ArticlePage {
            thumbnail {
              id

              rendition(width: 400) {
                url
              }
            }
          }
        }
      }
    }
  }
`;
