import React from "react";

import Link from "next/link";
import styled from "styled-components";

const Wrapper = styled.footer`
  background: var(--gold);
  @media (min-width: 767px) {
    padding: 50px 0;
  }
  nav {
    grid-gap: 10px;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    align-items: flex-start;
  }
  a {
    display: inline-block;
  }
  h5 {
    text-transform: uppercase;
    font-size: 1.1em;
    display: inline-block;
    opacity: 0.7;
    border-bottom: 1px solid var(--color);
    padding-bottom: 5px;
  }
`;
const Menu = styled.nav`
  grid-column: 2 / span 1;
`;

const Bottom = styled.div`
  max-width: calc(0.6 * var(--content-w));
  margin: 0 auto;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 2em;
  padding-top: 1em;
  h3 {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 12px;
    line-height: 1em;
    font-family: var(--font-alt);
  }
`;
const Inner = styled.div`
  max-width: calc(0.8 * var(--content-w));
  margin: 0 auto;
  display: grid;
  grid-gap: 0 50px;
  grid-template-columns: 40% 30% 30%;
  @media (max-width: 1195px) {
    padding: var(--mobile-p);
    display: flex;
    flex-direction: column;
  }
`;

export default function Footer({ networks, pages }) {
  return (
    <Wrapper>
      <Inner>
        <div>
          <h5>À propos</h5>
          <div
            dangerouslySetInnerHTML={{
              __html:
                pages &&
                pages.filter(({ __typename }) => __typename === "HomePage")[0]
                  .footer, // Only one HomePage is allowed on the backend, so this is safe to get it like that.
            }}
          />
        </div>
        <div>
          <h5>Plan du site</h5>
          <Menu>
            {pages &&
              pages
                .filter(({ __typename }) => __typename === "HomePage")[0] // Only one HomePage is allowed on the backend, so this is safe to get it like that.
                .children.map(
                  ({ title, slug }, index) =>
                    slug &&
                    title && (
                      <Link
                        key={slug}
                        href={`/${slug}`}
                        // className="item"
                        // activeClassName="active">
                      >
                        {title}
                      </Link>
                    )
                )}
          </Menu>
        </div>
        <div>
          <h5>Me retrouver</h5>
          <nav>
            {networks.map(({ link, name }) => (
              <a
                href={link}
                key={name}
                target="_blank"
                rel="noreferrer noopener"
              >
                {name}
              </a>
            ))}
          </nav>
        </div>
      </Inner>
      <Bottom>
        <h3>
          {
            pages &&
              pages.filter(({ __typename }) => __typename === "HomePage")[0]
                .footerSeo // Only one HomePage is allowed on the backend, so this is safe to get it like that.
          }
        </h3>
      </Bottom>
    </Wrapper>
  );
}
