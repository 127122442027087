import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled, { keyframes } from "styled-components";
import { HamburgerSpring } from "react-animated-burgers";
import Logo from "./logo-mobile";

const CONTACT_PAGE_ID = 6;

// TODO: Properly type this component
const Burger: any = styled(HamburgerSpring)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  @media (min-width: 767px) {
    display: none;
  }
  transform: scale(0.75);
`;

const translateIn = keyframes`
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform :translateX(0);
    opacity: 1;
  }
`;

const Wrapper = styled.header`
  display: flex;
  margin: 0 auto;
  font-size: 0.9em;

  max-width: calc(var(--content-w) + 300px);
  margin: 0 auto;
  padding: 30px 0 20px 0;
  justify-content: center;

  nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @media (max-width: 1195px) and (min-width: 767px) {
      justify-content: flex-end;
      text-align: center;
      --offset: 30px;
      a {
        margin-bottom: calc(-1 * var(--offset));
      }
      padding-bottom: var(--offset);
    }
    @media (min-width: 767px) {
      a {
        margin-right: 2em;
      }
    }
    a:before {
      bottom: -15px;
    }
  }
  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: var(--background);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    margin: 0;
    padding: 0;
    align-content: center;
    justify-content: center;
    align-items: center;
    transform: translateY(-25vh);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s, transform 0.5s;
    a {
      opacity: 0;
    }
    &.visible {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
      a {
        opacity: 1;
      }
      .item {
        transform: translateX(-20px);
        opacity: 0;
        animation: ${translateIn} 0.5s linear;
        animation-fill-mode: forwards;
      }
    }

    nav {
      grid-gap: 30px;
      flex-direction: column;
    }
  }
`;

export default function Header({ siteTitle, pages }) {
  const [visible, setVisibility] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (visible) {
      setVisibility(false);
    }
  }, [router]);

  return (
    <>
      <Burger isActive={visible} onClick={() => setVisibility(!visible)} />
      <Wrapper className={visible && "visible"}>
        <nav>
          <Link
            href="/"
            passHref={true}
            style={{ width: 100, height: 100, position: "relative" }}
            className="logo"
          >
            <Logo />
          </Link>
          {pages &&
            pages
              .filter(({ __typename }) => __typename === "HomePage")[0] // Only one HomePage is allowed on the backend, so this is safe to get it like that.
              .children.filter(
                ({ excludeFromMenus }) => excludeFromMenus === false,
              )
              .map(({ id, title, slug }, index) => {
                if (!title || !slug || id === "34") return;
                return (
                  <Link
                    key={slug}
                    href={`/${slug}`}
                    className={`${
                      parseInt(id) === CONTACT_PAGE_ID ? "cta" : undefined
                    } item`}
                    style={{
                      animationDelay: `${index * 0.2}s`,
                    }}
                  >
                    {title}
                  </Link>
                );
              })}
        </nav>
      </Wrapper>
    </>
  );
}
