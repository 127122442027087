import React from "react";
import Image from "next/image";

export default function LogoMobile() {
  return (
    <Image
      src="/logo-mobile.webp"
      alt=""
      width={100}
      height={100}
    />
  );
}
